import { Loader } from '@/libs/UI/Jammer'
import { useLanguage } from '@/libs/context/LanguageProvider'
import { useCreateRobots, useGetRobots } from '@/services/API/robots/hook'
import { TRobots } from '@/services/API/robots/robots.type'
import { useRobotsStore } from '@/store/robotsStore/robotsStore'
import { useCallback, useEffect, useState } from 'react'
import RobotsEdit from './RobotsEdit/RobotsEdit'
import s from './WrapperRobots.module.scss'
import { ActionRightBlock } from './_comp'
import WrapperAddNewResource from '../_common/_comp/WrapperAddNewResource'

const WrapperRobots = () => {
	const [isActiveEdit, setActiveEdit] = useState(false)
	const [saveOld, setSaveOld] = useState<TRobots[]>([])
	const { data, isLoading, isError, isSuccess } = useGetRobots()
	const { getForReq, setStore } = useRobotsStore()
	const { mutateAsync: updateRobots } = useCreateRobots()
	const { getLocalization } = useLanguage()
	//
	const save = useCallback(() => {
		updateRobots(getForReq())
	}, [])
	const reset = useCallback(() => {
		setStore(saveOld)
	}, [saveOld])
	const changeActive = useCallback(() => {
		setActiveEdit((prev) => !prev)
	}, [])
	///
	useEffect(() => {
		if (isSuccess && data) {
			setStore(data.items)
			setSaveOld(data.items)
		}
	}, [data, isSuccess])

	///
	if (isLoading) {
		return <Loader params={{ visible: isLoading }} />
	}
	return (
		<WrapperAddNewResource
			title={getLocalization('Robots')}
			goBack={true}
			rightComp={
				<ActionRightBlock {...{ isActiveEdit, save, reset, changeActive }} />
			}
		>
			<div className={s.wrap}>
				<div className={s.main_block}>
					{isLoading ? (
						<Loader params={{ visible: isLoading }} />
					) : (
						<RobotsEdit {...{ isActiveEdit }} />
					)}
				</div>
			</div>
		</WrapperAddNewResource>
	)
}

export default WrapperRobots
