import { H1, P } from '@/libs/UI/CustomTags'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import s from './HeaderSetting.module.scss'
import { THeaderSetting } from './type'
import { ArrowBackSvg } from '@/_assets/svg/arrows'

const HeaderSetting: FC<THeaderSetting> = ({
	title,
	rightComp = null,
	goBack = false,
}) => {
	const navigate = useNavigate()

	const handleBack = () => {
		navigate(-1) // Возврат на одну страницу назад в истории
	}
	return (
		<div className={s.headerSetting}>
			<div className={s.title}>
				{goBack && (
					<div onClick={handleBack}>
						<ArrowBackSvg />
					</div>
				)}
				<H1 size="m">{title}</H1>
			</div>
			{rightComp && rightComp}
		</div>
	)
}
export default HeaderSetting
