import { useLanguage } from '@/libs/context/LanguageProvider'
import { FC, useEffect, useState } from 'react'
import { TTabItem } from '../../../../components/Tabs/type'
import EmbeddedContent from '../../_common/AddNewResource/EmbeddedContent/EmbeddedContent'
import ReceivingData from '../../_common/AddNewResource/ReceivingData/ReceivingData'
import SettingWidget from '../../_common/AddNewResource/SettingWidget/SettingWidget'
import s from './WrapperAddNewNews.module.scss'

import Tabs from '@/components/Tabs/Tabs'
import WrapperAddNewResource from '../../_common/_comp/WrapperAddNewResource'

import useTabs from '@/libs/hooks/use-tabs'
import { Loader } from '@/libs/UI/Jammer'
import {
	useCreateNews,
	useDeleteNewsById,
	useGetNewsById,
	useGetTagsForNewsById,
	useUpdateNewsById,
	useUpdateTagsForNewsById,
} from '@/services/API/news/hook'
import {
	TNewsCreateReq,
	TNewsUpdateReq,
	TSingleNewsRes,
} from '@/services/API/news/news.type'
import { useConstructorStore } from '@/store/newResourceStore/_common/constructor/constructorStore'
import { useSettingStore } from '@/store/newResourceStore/_common/setting/settingStore'
import useNewsStore, { useSeoStore } from '@/store/newResourceStore/news'
import { TNewsKeysTabs } from '@/store/newResourceStore/news/newsStore.type'
import { TTypeTagNews } from '@/store/newResourceStore/news/seo/seo.type'
import { HeaderActions } from '../../_common/_comp'
import { useManipulationData } from '../../_common/_hooks'
import { TEditingData } from '../../_common/_hooks/use-manipulation-data/type'
import { VariantNewResourceContext } from '../../_common/AddNewResource/ReceivingData/_context/VariantNewResourceContext'
import Tags from '../../_common/AddNewResource/ReceivingData/Tags/Tags'
import { TVariantOpenPage } from '../../Constructor.type'
import SeoResource from './SeoResource/SeoResource'

const INIT_TABS = [
	{
		key: 'seo',
		label: 'SEO',
		isActive: true,
	},
	{
		key: 'content',
		label: 'Контент',
		isActive: false,
	},
	{
		key: 'tags',
		label: 'Теги',
		isActive: false,
	},
] as TTabItem<TNewsKeysTabs>[]
const WrapperAddNewNews: FC<TVariantOpenPage> = ({
	editFor,
	labelPage,
	id = -1,
}) => {
	const { getLocalization } = useLanguage()
	const { activeTab, changeTabs, tabs } = useTabs<TNewsKeysTabs>(INIT_TABS)
	const { newsObj, bindActionData, bindTransformData } = useNewsStore()
	const { bindStore } = useConstructorStore()
	const settings = useSettingStore()

	/////////

	const [editingData, setEditingData] = useState<TEditingData<TSingleNewsRes>>({
		data: null,
	})

	const { mutateAsync: createItem } = useCreateNews()
	const { mutateAsync: deleteItem } = useDeleteNewsById()
	const { mutateAsync: getItem } = useGetNewsById()
	const { mutateAsync: updateItem } = useUpdateNewsById()
	const { mutateAsync: updateTags } = useUpdateTagsForNewsById()

	/////////

	const { handleSentData, handleCopyStore, handleLocalRemove } =
		useManipulationData({
			bindActionData: {
				loadLocalData: ({ isDelete }) => {
					const data = !isDelete
						? {
								...newsObj,
								id: Number(new Date()),
								seoStore: {
									...newsObj.seoStore,
									link: '',
								},
						  }
						: {}
					return bindActionData.loadNewsData(data)
				},
				removeLocalData: bindActionData.removeNewsData,
				updateLocalData: bindActionData.updateNewsData,
			},
			copyArray: {
				...newsObj,
				id: Number(new Date()),
				seoStore: {
					...newsObj.seoStore,
					link: '',
				},
			},
			bindTransformData,
			variantResource: 'news',
			editingData,
			editFor,
		})

	const fetchSentData = async () => {
		const sentSetting = handleSentData(settings.getCurrentLang('news'))

		if (sentSetting !== null) {
			if (editFor === 'ADD') {
				await createItem(sentSetting.sentData as TNewsCreateReq)
					.then((e) => {
						sentSetting.clear()
						return e
					})
					.then((e) => {
						updateTags({
							news_id: e.id,
							ids: [...seoData.tags.map((tag) => tag.id)],
						})
					})
			}
			if (editFor === 'UPDATE') {
				await updateItem({
					id,
					body: sentSetting.sentData as TNewsUpdateReq,
				})
					.then(() => {
						sentSetting.clear()
					})
					.then(() => {
						updateTags({
							news_id: id,
							ids: [...seoData.tags.map((tag) => tag.id)],
						})
					})
			}
		}
	}

	const fetchDeleteNews = async () => {
		if (editFor === 'UPDATE') {
			const _id = id || newsObj?.id || -1
			await deleteItem({
				lang: settings.getCurrentLang('news'),
				id: _id,
			}).then(() => {
				handleLocalRemove()
			})
		} else if (editFor === 'ADD') {
			handleLocalRemove()
		}
	}

	/////////

	useEffect(() => {
		if (id > -1) {
			getItem({ id, lang: settings.getCurrentLang('news') }).then((e) => {
				setEditingData({
					data: e.dataRes,
				})
			})
		}
	}, [id, settings.getCurrentLang('news')])

	///
	const [allTags, setAllTags] = useState<TTypeTagNews[]>([])
	const { data: tagsData } = useGetTagsForNewsById({
		lang: settings.getCurrentLang('news'),
		size: 50,
		page: 1,
	})
	const { seoData, setTags } = useSeoStore()
	useEffect(() => {
		if (tagsData && tagsData?.items?.length > 0) {
			setAllTags(tagsData?.items)
		}
	}, [tagsData])

	if (editFor === 'UPDATE' && !editingData.data)
		return (
			<Loader
				params={{
					visible: !editingData.data,
				}}
			/>
		)

	return (
		<VariantNewResourceContext.Provider value={{ variantResource: 'news' }}>
			<WrapperAddNewResource
				title={getLocalization(labelPage)}
				goBack={true}
				rightComp={
					<HeaderActions
						disabled={false}
						handleUpdateStore={bindActionData.updateNewsData}
						handleCopyStore={handleCopyStore}
					/>
				}
			>
				<div className={s.wrap}>
					<div className={s.main_block}>
						<Tabs
							data={tabs}
							callback={changeTabs}
							saveData={bindActionData.updateNewsData}
						/>
						{activeTab.key === 'seo' && <SeoResource />}
						{activeTab.key === 'content' && <ReceivingData />}
						{activeTab.key === 'tags' && (
							<Tags
								dataTags={seoData.tags}
								changeStoreData={setTags}
								{...{ allTags }}
							/>
						)}
					</div>
					<div className={s.widgets_block}>
						{activeTab.key === 'content' && <EmbeddedContent />}
						<SettingWidget
							handleRemove={fetchDeleteNews}
							handleSent={fetchSentData}
							editFor={editFor}
						/>
					</div>
				</div>
			</WrapperAddNewResource>
		</VariantNewResourceContext.Provider>
	)
}
export default WrapperAddNewNews
