import { routes } from '@/constants/routes'
import {
	TCasinoItemRes,
	TUpdateCasino,
} from '@/services/API/casino/casino.type'
import { useUpdateCasinoById } from '@/services/API/casino/hook'
import { TVariantResource } from '@/store/newResourceStore/type'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ItemAddedContent } from '../../_common/_comp'
import s from './ListAddedCasino.module.scss'
import { TLocationState } from '@/screens/update/type'
type TProps = {
	variantContent: TVariantResource
	data: TCasinoItemRes[]
}
const ListAddedCasino: FC<TProps> = ({ variantContent, data }) => {
	const navigate = useNavigate()
	const { mutateAsync: updateItem } = useUpdateCasinoById()
	const fetchUpdate = async (params: TUpdateCasino) => {
		await updateItem({
			id: params.id,

			body: {
				casino: { ...params, hidden: !params.hidden },
			},
		})
	}
	return (
		<div className={s.list}>
			{data.map((casino) => {
				return (
					<ItemAddedContent
						key={casino.id}
						id={casino.id}
						isHidden={casino.hidden}
						title={casino.name}
						variantContent={variantContent}
						callbackVisible={() => {
							fetchUpdate(casino)
						}}
						callbackEdit={() => {
							navigate(routes.UPDATE_CASINO, {
								state: {
									itemId: casino.id,
									bind_id: casino.bind_id,
								},
							} as TLocationState)
						}}
					/>
				)
			})}
		</div>
	)
}
export default ListAddedCasino
