import { toastGetItemLang } from '@/libs/utils/toastGetAllLang'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import {
	createCasinoById,
	deleteCasinoById,
	getAllPaymentsForCasino,
	getCasinoById,
	getPaymentsForCasinoById,
	getTopCasino,
	updateCasinoById,
	updatePaymentsForCasinoById
} from './casino'
import {
	TCasinoCreateReq,
	TCasinoDeleteRes,
	TParamsAddPaymentsToCasinoReq,
	TParamsCasinoListReq,
	TParamsCasinoPaymentsListReq,
	TParamsCasinoUpdateReq,
	TParamsSingleCasinoReq,
} from './casino.type'

export const useGetTopCasino = (params: TParamsCasinoListReq) => {
	return useQuery(['topCasino', params.lang], () => getTopCasino(params), {
		onSuccess: (data) => {},
		onError: (error) => {
			console.log('get all casino', error)
		},
	})
}

export const useGetCasinoById = () => {
	return useMutation(
		async (params: TParamsSingleCasinoReq) => {
			const [data] = await Promise.allSettled([getCasinoById(params)])
			toastGetItemLang({
				lang: params.lang,
				status: data.status,
			})

			return {
				dataRes: data.status === 'fulfilled' ? data.value : null,
				error: data.status === 'rejected' ? data.reason : null,
			}
		},
		{
			onSuccess: (data) => {},
			onError: (error) => {
				console.log('get all langs news', error)
			},
		}
	)
}

export const useGetAllPaymentsForCasino = (
	params: TParamsCasinoPaymentsListReq
) => {
	return useQuery(
		['getAllPaymentsForCasino', params.lang],
		() => getAllPaymentsForCasino(params),
		{
			onSuccess: (data) => {},
			onError: (error) => {
				console.log('get all casino payments', error)
			},
			refetchOnWindowFocus: false,
		}
	)
}

export const useGetPaymentsForCasinoById = () => {
	return useMutation(
		async (params: Pick<TParamsAddPaymentsToCasinoReq, 'casino_id'>) => {
			const data = await getPaymentsForCasinoById(params)
			return data
		},
		{
			onSuccess: (data) => {},
			onError: (error) => {
				console.log('get all langs news', error)
			},
		}
	)
}

export const useUpdatePaymentsForCasinoById = () => {
	return useMutation(
		async (params: TParamsAddPaymentsToCasinoReq) => {
			return await updatePaymentsForCasinoById(params)
		},
		{
			onSuccess: (data) => {},
			onError: (error) => {
				toast.error('Ошибка добавления платежей')
				console.log('add payments', error)
			},
		}
	)
}

export const useCreateCasino = () => {
	return useMutation(
		async ({ casino }: TCasinoCreateReq) => {
			return await createCasinoById({ casino })
		},
		{
			onSuccess: (data) => {
				toast.success('Казино добавлено')
			},
			onError: (error) => {
				toast.error('Ошибка добавления')
				console.log('create casino', error)
			},
		}
	)
}

export const useUpdateCasinoById = () => {
	const queryClient = useQueryClient()
	return useMutation(
		async (params: TParamsCasinoUpdateReq) => {
			return await updateCasinoById(params)
		},
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries('topCasino')
				toast.success('Казино обновлено')
			},
			onError: (error) => {
				toast.error('Ошибка обновления')
				console.log('update casino', error)
			},
		}
	)
}

export const useDeleteCasinoById = () => {
	return useMutation(
		async ({ lang, id }: TCasinoDeleteRes) => {
			return await deleteCasinoById({ lang, id })
		},
		{
			onSuccess: (data) => {
				toast.success('Казино удалено')
			},
			onError: (error) => {
				toast.error('Ошибка удаления')
				console.log('delete casino', error)
			},
		}
	)
}
