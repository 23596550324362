import { MoreEditSvg, PlusSvg } from '@/_assets/svg/editTag'
import { ModalCatEdit } from '@/components/Modal'
import { useModal } from '@/libs/HOC/ModalHOC/ModalHOC'
import { TCategoriesRes } from '@/services/API/categories/categories.type'
import { FC } from 'react'
import { THandleEditParams } from '../../CatVariantResource/CatVariantResource.type'
import s from './CatVariantEdit.module.scss'
type TProps = {
	category?: TCategoriesRes | null
	handleEdit: (params: THandleEditParams) => void
}
const CatVariantEdit: FC<TProps> = ({ category, handleEdit }) => {
	const { openModal } = useModal()
	return (
		<div className={s.container}>
			<div
				onClick={() => {
					openModal({
						component: (
							<ModalCatEdit
								variantEdit="create"
								parent_id={category?.id}
								actions={{
									callbackSuccess: (params) =>
										handleEdit({
											data: params,
											variantEdit: 'create',
										}),
								}}
							/>
						),
						settingModal: {},
					})
				}}
			>
				<PlusSvg />
			</div>
			{category && (
				<div
					onClick={() => {
						openModal({
							component: (
								<ModalCatEdit
									variantEdit="update"
									category={category}
									actions={{
										callbackRemove: (params) => {
											handleEdit({
												data: params,
												variantEdit: 'remove',
											})
										},
										callbackSuccess: (params) =>
											handleEdit({
												data: params,
												variantEdit: 'update',
											}),
									}}
								/>
							),
							settingModal: {
								isOuterClose: true,
							},
						})
					}}
				>
					<MoreEditSvg />
				</div>
			)}
		</div>
	)
}
export default CatVariantEdit
