import { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
	Navigate,
	Route,
	BrowserRouter as Router,
	Routes,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Container from './components/Container/Container'
import Header from './components/Header/Header'
import WrapperConfig from './components/WrapperConfig'

import { routes } from '@/constants/routes'
import WrapperResourse from './features/Constructor'
import { LanguageProvider } from './libs/context/LanguageProvider'
import AddNewArticle from './screens/add/AddNewArticle'
import AddNewCasino from './screens/add/AddNewCasino'
import AddNewHistory from './screens/add/AddNewHistory'
import AddNewNews from './screens/add/AddNewNews'
import Articles from './screens/Articles'
import Auth from './screens/Auth'
import Casino from './screens/Casino'
import History from './screens/History'
import News from './screens/News'
import Robots from './screens/Robots'
import UpdateArticle from './screens/update/UpdateArticle'
import UpdateCasino from './screens/update/UpdateCasino'
import UpdateHistory from './screens/update/UpdateHistory'
import UpdateNews from './screens/update/UpdateNews'
import { useAuthStore } from './store/authStore/authStore'
import { ModalProvider } from './libs/HOC'
import Categories from './screens/Categories'

function App() {
	const { isAuthenticated, checkAuthentication } = useAuthStore()

	const queryClient = new QueryClient()

	useEffect(() => {
		checkAuthentication()
	}, [checkAuthentication])

	return (
		<QueryClientProvider client={queryClient}>
			<LanguageProvider>
				<ModalProvider>
					<Router>
						<ToastContainer
							autoClose={500}
							limit={2}
							pauseOnFocusLoss={false}
							position="bottom-right"
							theme="dark"
							pauseOnHover={false}
							closeOnClick
						/>
						<Container>
							<Header />
							<Routes>
								<Route path="/" element={<Auth />} />
								{isAuthenticated ? (
									<>
										<Route path={routes.ADMIN_PAGE} element={<WrapperConfig />}>
											<Route path={routes.CASINO} element={<Casino />}>
												<Route
													path={``}
													element={<WrapperResourse variantContent="casino" />}
												/>
												<Route
													path={`${routes.ADD_CASINO}`}
													element={<AddNewCasino />}
												/>
												<Route
													path={`${routes.UPDATE_CASINO}`}
													element={<UpdateCasino />}
												/>
											</Route>
											<Route path={routes.NEWS} element={<News />}>
												<Route
													path={``}
													element={<WrapperResourse variantContent="news" />}
												/>
												<Route
													path={`${routes.ADD_NEWS}`}
													element={<AddNewNews />}
												/>
												<Route
													path={`${routes.UPDATE_NEWS}`}
													element={<UpdateNews />}
												/>
											</Route>
											<Route path={routes.ARTICLE} element={<Articles />}>
												<Route
													path={``}
													element={
														<WrapperResourse variantContent="articles" />
													}
												/>
												<Route
													path={`${routes.ADD_ARTICLE}`}
													element={<AddNewArticle />}
												/>
												<Route
													path={`${routes.UPDATE_ARTICLE}`}
													element={<UpdateArticle />}
												/>
											</Route>
											<Route path={routes.HISTORY} element={<History />}>
												<Route
													path={``}
													element={<WrapperResourse variantContent="history" />}
												/>
												<Route
													path={routes.ADD_HISTORY}
													element={<AddNewHistory />}
												/>
												<Route
													path={routes.UPDATE_HISTORY}
													element={<UpdateHistory />}
												/>
											</Route>
											<Route
												path={routes.SETTING_ROBOTS}
												element={<Robots />}
											/>
											<Route
												path={routes.CATEGORIES}
												element={<Categories />}
											/>
										</Route>
										<Route
											path={routes.ACCOUNT}
											element={<div>Профиль пользователя</div>}
										/>
										<Route
											path="*"
											element={<Navigate to={routes.ADMIN_PAGE} />}
										/>
									</>
								) : (
									<Route path="*" element={<Navigate to="/" />} />
								)}
							</Routes>
						</Container>
					</Router>
				</ModalProvider>
			</LanguageProvider>
		</QueryClientProvider>
	)
}

export default App
