import useArticleStore from '../article'
import useCasinoStore from '../casino'
import useHistoryStore from '../history'
import useNewsStore from '../news'
import { TVariantResource } from '../type'

type TProps = {
	variantResource: TVariantResource
}

const useLoadJSON = ({ variantResource }: TProps) => {
	const { bindActionData: bindCasino } = useCasinoStore()
	const { bindActionData: bindArticles } = useArticleStore()
	const { bindActionData: bindNews } = useNewsStore()
	const { bindActionData: bindHistory } = useHistoryStore()

	switch (variantResource) {
		case 'casino':
			return { bindResource: bindCasino.loadCasinoData }
		case 'articles':
			return { bindResource: bindArticles.loadArticleData }
		case 'news':
			return { bindResource: bindNews.loadNewsData }
		case 'history':
			return { bindResource: bindHistory.loadHistoryData }
	}
}

export default useLoadJSON
