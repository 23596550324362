import React, { useState } from "react";
import { useMutation } from "react-query";

import ImageDeleteSvg from "@/_assets/svg/ImageDeleteSvg";
import ImageEditSvg from "@/_assets/svg/ImageEditSvg";
import InputFileSvg from "@/_assets/svg/InputFileSvg";

import ImageCdn from "@/libs/UI/ImageCdn/ImageCdn";
import LoaderImage from "@/libs/UI/Jammer/LoaderImage/LoaderImage";
import { deleteFile, uploadFile } from "@/services/API/cdn/upload-image";
import { toast } from "react-toastify";
import s from "./UploadImage.module.scss";

type TInputFile = {
	fileURL: string;
	onChange: (file: string) => void;
	idInput: string;
};

const UploadImage = ({ fileURL, onChange, idInput }: TInputFile) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { mutate } = useMutation(
		["uploadFile"],
		({ image }: { image: File }) => {
			if (image) {
				return uploadFile({ image });
			} else {
				throw new Error("No image file selected");
			}
		},
		{
			onSuccess: async (data) => {
				try {
					if(fileURL !== data.id) {
						deleteImage({id: fileURL})
					}
					onChange(data.id);
				} catch (err) {
					console.log(err);
				} finally {
					setIsLoading(false);
				}
			},
			onError: () => {
				toast.error('Не удалось загрузить изображение');
				setIsLoading(false);
			}
		}
	);

	const { mutate: deleteImage } = useMutation(
		["deleteImage"], 
		({ id }: { id: string }) => {
			if (id) {
				return deleteFile({ id });
			} else {
				throw new Error("No image file selected");
			}
		},
	)

	// const generateUuid = (): string => {
	// 	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (char) => {
	// 		const random = (Math.random() * 16) | 0;
	// 		const value = char === "x" ? random : (random & 0x3) | 0x8;
	// 		return value.toString(16);
	// 	});
	// };

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files || !e.target.files[0]) {
			return;
		}
		if(e.target.files[0].size < 1 * 1024 * 1024){
			//const newUuid = generateUuid();
			const file = e.target.files[0];
			mutate({ image: file });
			setIsLoading(true);
		} else {
			toast.error('Файл слишком большой')
		}
	};

	const handleDelete = (e: React.MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		deleteImage({id: fileURL})
		onChange("");
	};

	const handleEdit = (e: React.MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		const fileInput = document.getElementById(idInput) as HTMLInputElement;
		if (fileInput) {
			fileInput.click();
		}
	};
	return (
		<label htmlFor={idInput} className={s.dropWrapper}>
			{isLoading ? (
				<LoaderImage />
			) : fileURL === "" ? (
				<>
					<p className={s.dropTitle}>
						Загрузите <br /> изображение
					</p>
					<InputFileSvg />
				</>
			) : (
				<>
					<ImageCdn
						src={fileURL}
						alt={"uploaded file"}
					/>
					<div className={s.menu}>
						<div onClick={handleDelete}>
							<ImageDeleteSvg />
						</div>
						<div onClick={handleEdit}>
							<ImageEditSvg />
						</div>
					</div>
				</>
			)}
			<input
				type="file"
				// id="file-drop"
				id={idInput}
				className={s.dropInput}
				required
				onChange={handleChange}
			/>
		</label>
	);
};

export default UploadImage;
