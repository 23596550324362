import { CloseWithBgcSvg } from '@/_assets/svg/close'
import { useModal } from '@/libs/HOC/ModalHOC/ModalHOC'
import { Input } from '@/libs/UI'
import { Button, P } from '@/libs/UI/CustomTags'
import { generateLink } from '@/libs/utils/generateLink'
import { FC, useEffect, useState } from 'react'
import { TLangKey, useLanguage } from '../../../libs/context/LanguageProvider'
import s from './ModalCatEdit.module.scss'
import {
	THandleChangeFromParams,
	TModalCatEditProps,
	TStateModalCat,
} from './ModalCatEdit.type'
import { TCategoriesRes } from '@/services/API/categories/categories.type'

const INIT_DATA: TCategoriesRes = {
	link: '',
	id: 0,
	parent_id: null,
	title: '',
	children: [],
	translations: {
		en: {
			cat_name: '',
			meta_title: '',
			meta_description: '',
		},
		ru: {
			cat_name: '',
			meta_title: '',
			meta_description: '',
		},
	},
}

const ModalCatEdit: FC<TModalCatEditProps> = ({
	actions,
	category = null,
	variantEdit,
	parent_id = null,
}) => {
	const [formData, setFormData] = useState<TStateModalCat>(INIT_DATA)
	const { closeModal } = useModal()
	const { getLocalization } = useLanguage()
	///
	const handleChangeForm = ({ key, value, lang }: THandleChangeFromParams) => {
		setFormData((prev) => {
			if (lang) {
				return {
					...prev,
					translations: {
						...prev.translations,
						[lang]: {
							...prev.translations[lang],
							[key]: value,
						},
					},
				}
			}
			return {
				...prev,

				link: key === 'title' ? generateLink(value) : prev.link,
				[key]: value,
			}
		})
	}

	///

	useEffect(() => {
		if (category) {
			const transformData: TCategoriesRes = {
				...category,
				translations: category.translations,
			}
			setFormData(transformData)
		}
	}, [category])

	return (
		<div className={s.container}>
			<div className={s.close} onClick={() => closeModal()}>
				<CloseWithBgcSvg />
			</div>
			<div className={s.header}>
				<P size="xl">{category ? formData.title : 'Новый тег'}</P>
			</div>
			<div className={s.wrapForm}>
				<div className={s.blockInputs}>
					<Input
						placeholder="Title"
						placeholder_type="is_shown"
						value={formData.title}
						onChange={(e) =>
							handleChangeForm({
								key: 'title',
								value: e.target.value,
							})
						}
					/>
					<Input
						placeholder="Link"
						value={formData.link}
						placeholder_type="is_shown"
						onChange={(e) =>
							handleChangeForm({
								key: 'link',
								value: e.target.value,
							})
						}
					/>

					<div className={s.blockByLang}>
						{(Object.keys(formData.translations) as TLangKey[]).map((lang) => {
							return (
								<div className={s.boxByLang} key={lang}>
									<P size="xs" color="grey">
										Язык:<P size="xs">{lang.toUpperCase()}</P>
									</P>
									<Input
										placeholder="Cat name"
										placeholder_type="is_shown"
										value={formData.translations[lang].cat_name}
										onChange={(e) =>
											handleChangeForm({
												key: 'cat_name',
												value: e.target.value,
												lang: lang,
											})
										}
									/>
									<Input
										placeholder="Meta Title"
										placeholder_type="is_shown"
										value={formData.translations[lang].meta_title}
										onChange={(e) =>
											handleChangeForm({
												key: 'meta_title',
												value: e.target.value,
												lang: lang,
											})
										}
									/>
									<Input
										placeholder="Meta description"
										placeholder_type="is_shown"
										value={formData.translations[lang].meta_description}
										onChange={(e) =>
											handleChangeForm({
												key: 'meta_description',
												value: e.target.value,
												lang: lang,
											})
										}
									/>
								</div>
							)
						})}
					</div>
				</div>

				<div className={s.actions}>
					{variantEdit === 'update' && (
						<Button
							type="empty"
							onClick={() => {
								actions?.callbackRemove &&
									category?.id &&
									actions?.callbackRemove({
										...formData,
									})
								closeModal()
							}}
						>
							{getLocalization('Удалить')}
						</Button>
					)}

					<Button
						type="primary"
						onClick={() => {
							if (variantEdit === 'create') {
								actions?.callbackSuccess({
									...formData,
									parent_id: parent_id,
								})
							}
							if (variantEdit === 'update') {
								actions?.callbackSuccess({
									...formData,
								})
							}
							closeModal()
						}}
					>
						{getLocalization('Сохранить')}
					</Button>
				</div>
			</div>
		</div>
	)
}

export default ModalCatEdit
