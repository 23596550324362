import PlusSvg from '@/_assets/svg/PlusSvg'
import { ModalCatEdit } from '@/components/Modal'
import { useModal } from '@/libs/HOC/ModalHOC/ModalHOC'
import { P } from '@/libs/UI/CustomTags'
import { TCategoriesRes } from '@/services/API/categories/categories.type'
import { FC, useRef } from 'react'
import { THandleEditParams } from '../../CatVariantResource/CatVariantResource.type'
import ItemEditCat from './ItemEditCat/ItemEditCat'
import s from './ListEditCat.module.scss'

type TProps = {
	categories: TCategoriesRes[]
	handleEdit: (params: THandleEditParams) => void
}
const ListEditCat: FC<TProps> = ({ categories, handleEdit }) => {
	const colors = useRef(
		['#2CDD82', '#CC345D', '#00CFF2', '#FFEC38', '#FF9159'].sort(
			() => Math.random() - 0.5
		)
	).current
	const { openModal } = useModal()
	return (
		<div className={s.container}>
			{!!!categories.length && (
				<div
					className={s.empty}
					onClick={() => {
						openModal({
							component: (
								<ModalCatEdit
									variantEdit="create"
									actions={{
										callbackSuccess: (data) =>
											handleEdit({
												data,
												variantEdit: 'create',
											}),
									}}
								/>
							),
							settingModal: {
								isOuterClose: true,
							},
						})
					}}
				>
					<PlusSvg />
					<P weight={500} color="green">
						Добавить
					</P>
				</div>
			)}
			{categories.map((category, index) => (
				<ItemEditCat
					key={category.id}
					category={category}
					isUpLevel={true}
					color={colors[index] ?? colors[0]}
					handleEdit={handleEdit}
				/>
			))}
		</div>
	)
}
export default ListEditCat
