import { SERVICES } from '@/constants/api'
import { API } from '@/services/helpers/conf-axios'
import {
	TCasinoCreateReq,
	TCasinoDeleteRes,
	TCasinoListRes,
	TCasinoPaymentsListRes,
	TParamsAddCasinoToPaymentsRes,
	TParamsAddPaymentsToCasinoReq,
	TParamsCasinoListReq,
	TParamsCasinoPaymentsListReq,
	TParamsCasinoUpdateReq,
	TParamsSingleCasinoReq,
	TSingleCasinoRes,
} from './casino.type'

export const getTopCasino = async ({
	lang,
	size,
	page,
	filters,
}: TParamsCasinoListReq): Promise<TCasinoListRes> => {
	try {
		const response = await API.get<any, { data: TCasinoListRes }>(
			`${SERVICES.casino}`,
			{
				params: {
					size,
					page,
					// ...filters
				},
				headers: {
					language: lang,
				},
			}
		)
		return response.data
	} catch {
		return {
			data: [],
		} as any
		throw new Error('fetch casino error')
	}
}

export const getCasinoById = async ({
	lang,
	id,
	bind_id,
}: TParamsSingleCasinoReq): Promise<TSingleCasinoRes> => {
	try {
		let additionParams = bind_id ? `/id/${bind_id}` : `/${id}`
		const response = await API.get<any, { data: TSingleCasinoRes }>(
			`${SERVICES.casino}${additionParams}`,
			{
				headers: {
					language: lang,
				},
			}
		)
		return response.data
	} catch {
		return {} as Promise<TSingleCasinoRes>
		// throw new Error('fetch casino by id error')
	}
}

export const getAllPaymentsForCasino = async ({
	lang,
	page,
	size,
}: TParamsCasinoPaymentsListReq): Promise<TCasinoPaymentsListRes> => {
	try {
		const response = await API.get<any, { data: TCasinoPaymentsListRes }>(
			`${SERVICES.casino}/payments`,
			{
				params: {
					page,
					size,
					// ...filters
				},
				headers: {
					language: lang,
				},
			}
		)
		return response.data
	} catch {
		return {
			data: [],
		} as any
		throw new Error('fetch articles error')
	}
}
export const getPaymentsForCasinoById = async ({
	casino_id,
}: Pick<TParamsAddPaymentsToCasinoReq, 'casino_id'>): Promise<TParamsAddCasinoToPaymentsRes> => {
	try {
		const response = await API.get<TParamsAddCasinoToPaymentsRes>(
			`${SERVICES.casino}/${casino_id}/payments`)
		return response.data
	} catch {
		return {
			data: [],
		} as any
		throw new Error('fetch articles error')
	}
}
export const updatePaymentsForCasinoById = async ({
	casino_id,
	ids,
}: TParamsAddPaymentsToCasinoReq): Promise<TParamsAddCasinoToPaymentsRes> => {
	try {
		const response = await API.patch<TParamsAddCasinoToPaymentsRes>(
			`${SERVICES.casino}/${casino_id}/payments`,
			ids
		)
		return response.data
	} catch {
		return {
			data: [],
		} as any
		throw new Error('fetch articles error')
	}
}

export const createCasinoById = async ({
	casino,
}: TCasinoCreateReq): Promise<TSingleCasinoRes> => {
	try {
		const response = await API.post<
			TCasinoCreateReq,
			{ data: TSingleCasinoRes }
		>(`${SERVICES.casino}`, {
			...casino,
		})
		return response.data
	} catch {
		throw new Error('create casino error')
	}
}

export const updateCasinoById = async ({
	id,
	body,
}: TParamsCasinoUpdateReq): Promise<TSingleCasinoRes> => {
	try {
		const response = await API.patch(`${SERVICES.casino}/${id}`, {
			...body.casino,
		})
		return response.data
	} catch {
		throw new Error('update casino by id error')
	}
}

export const deleteCasinoById = async ({
	lang,
	id,
}: TCasinoDeleteRes): Promise<any> => {
	try {
		const response = await API.delete(`${SERVICES.casino}/${id}`)
		return response.data
	} catch {
		throw new Error('delete casino by id error')
	}
}
